import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ApiService } from 'src/app/api.service';
import { Network } from 'src/app/models/SignupModels';
import { SharedDataService } from 'src/app/shared-data-service.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn, fadeIn } from 'ng-animate';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  animations: [ trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))])],

})
export class FooterComponent {
  portalURL: string;
  networks: Network[];
  showRhaDisclaimer = false;
  
  bounceIn: any;
  fadeIn: any;
  constructor(
    public auth: AuthService,
    private apiService: ApiService,
    private sharedDataService: SharedDataService,
  ) {
    
  }

  ngOnInit() {
    this.portalURL = this.sharedDataService.getData('portalURL');
    if(!this.portalURL)    {
      this.apiService.GetConfigurationParam("PortalURL").subscribe(res => { 
        this.portalURL = res; 
        this.sharedDataService.setData<string>('portalURL', res);
      });
    }
     // Check if user is authenticated
     this.auth.isAuthenticated$.subscribe(isAuthenticated => {
      if (isAuthenticated) {
        // Only call getMemberNetworks if the user is authenticated
        this.apiService.getMemberNetworks().subscribe(
          (networks: Network[]) => {
            this.networks = networks;

            this.showRhaDisclaimer = this.networks.some(network =>
              network.networkName === 'RHA' || network.networkName === 'Resort Hotel Association'
            );
          },
          (error) => {
            console.error('Error fetching networks:', error);
          }
        );
      }
    });
  }
}

